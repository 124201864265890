import "./Omi.css";

const sample = {"success":true,"powerfulmedical_system_id":"042caf2fd3b6","omi":false,"prediction":0.11863646239259148,"triage":null}

export function Omi({prediction = null}) {
    let result = prediction || sample;
    if (prediction == null) return "";
    return <>
        <h2>OMI (Occlusion Myocardial Infarction) Verdict</h2>
        <div className="omipred" style={{
            backgroundColor: result.omi ? "red" : "gray",
        }}>
            <div className="verdict">
                OMI was {result.omi ? <b>detected!</b> : "not detected."}
            </div>
            <div className="probability">
                OMI Prediction: {(result.prediction * 100).toFixed(2)}%.
            </div>
        </div>
    </>
}
