import "./DemoStep.css";

const stateMappings = {
    "future": "Run",
    "ready": "Run",
    "pending": "Running",
    "success": "✅ Succeeded",
    "failed": "❌ Failed",
}

export function DemoStep({
    onButtonClick,
    runName,
    title,
    description,
    curl,
    failureContent,
    state,
}) {
    function buttonClick (evt) {
        if (state != "ready") return;
        onButtonClick(evt);
    }

    return <div key={title} className={state == "future" ? "hidden-step" : ""}>
        <div className={!["ready", "pending"].includes(state) ? "hidden-step" : ""}>
        <h3>{title}</h3>
        <p>{description}</p>
        {curl != null ? <div class="code">{curl}</div> : ""}
        </div>
        <div class={`apibutton apibutton-${state}`} onClick={buttonClick}>
            {stateMappings[state]} {runName}
        </div>
        {failureContent != null ? failureContent : ""}
    </div>
}
