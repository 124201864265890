import {diagnosisNames} from "./mappings.js";
import "./Diagnoses.css";

const sample = {"success":true,"version":"04582b9e7a5a53f6f1c420619bc52002a427dfaa","model_identifier":"NzViOTQ2OTB8ZTY4MTU1Y2R8YzFiMTVlOGN8YjZiZGY2NjR8ZmUyNDQyZDN8ZTAyMDA1MGM=","bpm":46,"parameters":{"pr_interval":0,"qt_interval":493,"rr_interval":1286,"pp_interval":0,"qrs_complex":111,"p_wave":0,"qt_corrected":435,"axis":"rightaxis"},"diagnoses":[{"confidence":"low_conf","diagnosis":"trifasblocka"},{"confidence":"low_conf","diagnosis":"pcom"},{"confidence":"high_conf","diagnosis":"irbbb"},{"confidence":"high_conf","diagnosis":"venhyp"},{"confidence":"low_conf","diagnosis":"stemia"}]}

export function Diagnoses({prediction = null}) {
    let result = prediction || sample;
    if (prediction == null) return "";
    let rows = [];
    for (let d of result.diagnoses) {
        rows.push(<tr>
            <td>{diagnosisNames[d.diagnosis]}</td>
            <td className={d.confidence}></td>
        </tr>);
    }
    const diagnosesTable = <div className="diagnoses">
        <h3>Detected Diagnoses</h3>
        <table>
        <thead>
            <tr>
                <td>Detected Diagnosis</td>
                <td>Confidence</td>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table></div>
    const measurementsTable = <div className="parameters">
        <h3>Measurements</h3>
        <table>
        <tbody>
            <tr><td>BPM:</td><td>{result.bpm}</td></tr>
            <tr><td>PR Interval:</td><td>{result.parameters.pr_interval}</td></tr>
            <tr><td>PR Interval:</td><td>{result.parameters.pr_interval}</td></tr>
            <tr><td>QT Interval:</td><td>{result.parameters.qt_interval}</td></tr>
            <tr><td>RR Interval:</td><td>{result.parameters.rr_interval}</td></tr>
            <tr><td>PP Interval:</td><td>{result.parameters.pp_interval}</td></tr>
            <tr><td>QRS Complex:</td><td>{result.parameters.qrs_complex}</td></tr>
            <tr><td>P Wave:</td><td>{result.parameters.p_wave}</td></tr>
            <tr><td>QT Corrected:</td><td>{result.parameters.qt_corrected}</td></tr>
            <tr><td>Axis:</td><td>{result.parameters.axis}</td></tr>
        </tbody>
    </table></div>
    return <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }} className="diagnosticsSection">{diagnosesTable}{measurementsTable}</div>
}