export const SampleDigitalECG = {
    "powerfulmedical_system_id": "05fc6dafb0bd",
    "output": {
        "success": true,
        "leads": [
            {
                "version": "1.0",
                "lead_name": "I",
                "signal": [
                    null,
                    null,
                    null,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    0.0653533935546875,
                    0.08843231201171875,
                    0.11151123046875,
                    0.1500701904296875,
                    0.17215728759765625,
                    0.2011737823486328,
                    0.31290435791015625,
                    0.375,
                    0.4296875,
                    0.4935894012451172,
                    0.3212432861328125,
                    0.2955589294433594,
                    0.14069366455078125,
                    0.08231163024902344,
                    0.059825897216796875,
                    0.053955078125,
                    0.04606819152832031,
                    -0.0390625,
                    -0.125,
                    -0.0703125,
                    -0.0625,
                    -0.046875,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.1171875,
                    0.1171875,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.0859375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0390625,
                    0.078125,
                    0.1171875,
                    0.19433212280273438,
                    0.22275352478027344,
                    0.2691650390625,
                    0.328125,
                    0.390625,
                    0.4453125,
                    0.5078125,
                    0.5660381317138672,
                    0.2752647399902344,
                    0.2609100341796875,
                    0.243988037109375,
                    0.2270660400390625,
                    0.2101459503173828,
                    0.17603492736816406,
                    0.08020401000976562,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.1171875,
                    0.1171875,
                    0.1171875,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    0.0
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 618,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "II",
                "signal": [
                    null,
                    -0.04330730438232422,
                    -0.04352855682373047,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    0.18824100494384766,
                    0.21886634826660156,
                    0.24949169158935547,
                    0.29889965057373047,
                    0.34830760955810547,
                    0.6760787963867188,
                    0.7256383895874023,
                    0.8165950775146484,
                    0.9075517654418945,
                    0.9985084533691406,
                    1.2734375,
                    1.5535945892333984,
                    1.359375,
                    1.171875,
                    0.9813508987426758,
                    0.5874147415161133,
                    0.49286460876464844,
                    0.3968391418457031,
                    0.1015625,
                    0.078125,
                    0.0625,
                    0.046875,
                    0.0390625,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.28125,
                    0.28125,
                    0.2734375,
                    0.2578125,
                    0.25,
                    0.2421875,
                    0.234375,
                    0.2265625,
                    0.2109375,
                    0.1953125,
                    0.1796875,
                    0.1640625,
                    0.1484375,
                    0.140625,
                    0.1171875,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.0390625,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    0.046875,
                    0.1015625,
                    0.21875,
                    0.5761098861694336,
                    0.6233625411987305,
                    0.6937437057495117,
                    0.7861394882202148,
                    0.8350410461425781,
                    1.0859375,
                    1.3359375,
                    1.5819206237792969,
                    1.3359375,
                    1.09375,
                    0.84759521484375,
                    0.7674751281738281,
                    0.7072505950927734,
                    0.6470260620117188,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0625,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.28125,
                    0.28125,
                    0.2734375,
                    0.265625,
                    0.265625,
                    0.2578125,
                    0.25,
                    0.25,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.1953125,
                    0.1796875,
                    0.171875,
                    0.15625,
                    0.140625,
                    0.125,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.046875,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.03125,
                    0.0546875,
                    0.0546875,
                    0.046875
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 935,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "III",
                "signal": [
                    null,
                    null,
                    null,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    0.0,
                    0.03037738800048828,
                    0.1357593536376953,
                    0.16377925872802734,
                    0.19179916381835938,
                    0.25656700134277344,
                    0.4402904510498047,
                    0.5138235092163086,
                    0.5873565673828125,
                    0.6608896255493164,
                    0.6987037658691406,
                    0.8359375,
                    0.96875,
                    1.1030397415161133,
                    0.8671875,
                    0.624237060546875,
                    0.5260677337646484,
                    0.4257631301879883,
                    0.369354248046875,
                    0.203125,
                    0.1875,
                    0.15625,
                    0.140625,
                    0.1171875,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.203125,
                    0.203125,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.21875,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.203125,
                    0.203125,
                    0.1953125,
                    0.1875,
                    0.1796875,
                    0.171875,
                    0.1640625,
                    0.15625,
                    0.1484375,
                    0.1328125,
                    0.1328125,
                    0.125,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.046875,
                    0.0703125,
                    0.09375,
                    0.1171875,
                    0.3997468948364258,
                    0.4288501739501953,
                    0.45795345306396484,
                    0.4870567321777344,
                    0.5611343383789062,
                    0.734375,
                    0.9140625,
                    1.0908851623535156,
                    0.8984375,
                    0.7103672027587891,
                    0.6798820495605469,
                    0.5671882629394531,
                    0.5219545364379883,
                    0.4881725311279297,
                    0.4543905258178711,
                    0.171875,
                    0.1484375,
                    0.1328125,
                    0.125,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.1953125,
                    0.1953125,
                    0.1953125,
                    0.1875,
                    0.1875,
                    0.1796875,
                    0.171875,
                    0.1640625,
                    0.15625,
                    0.15625,
                    0.140625,
                    0.1328125,
                    0.125,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0007266998291015625,
                    0.001453399658203125,
                    0.0008325576782226562,
                    0.0009508132934570312,
                    0.0010690689086914062,
                    0.0009355545043945312,
                    0.0009355545043945312,
                    0.0007276535034179688,
                    0.0005197525024414062,
                    0.0007219314575195312,
                    0.0006656646728515625,
                    0.0006656646728515625,
                    0.00038909912109375,
                    0.000446319580078125,
                    0.0003948211669921875,
                    0.00034332275390625,
                    0.00048542022705078125,
                    0.0006275177001953125,
                    0.0007696151733398438,
                    0.000911712646484375,
                    0.0010538101196289062,
                    0.000301361083984375,
                    0.000301361083984375,
                    0.000301361083984375,
                    0.000301361083984375,
                    0.000301361083984375,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 1254,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "aVR",
                "signal": [
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.03125,
                    0.015625,
                    -0.1640625,
                    -0.1796875,
                    -0.34375,
                    -0.484375,
                    -0.6015625,
                    -0.7109375,
                    -0.828125,
                    -0.9375,
                    -1.0546875,
                    -0.671875,
                    -0.5390625,
                    -0.28125,
                    -0.2421875,
                    -0.1640625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.109375,
                    -0.109375,
                    -0.1171875,
                    -0.1171875,
                    -0.125,
                    -0.125,
                    -0.1328125,
                    -0.1328125,
                    -0.140625,
                    -0.140625,
                    -0.140625,
                    -0.1484375,
                    -0.15625,
                    -0.15625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.15625,
                    -0.15625,
                    -0.1484375,
                    -0.140625,
                    -0.1328125,
                    -0.125,
                    -0.1171875,
                    -0.109375,
                    -0.09375,
                    -0.0859375,
                    -0.078125,
                    -0.0625,
                    -0.046875,
                    -0.03125,
                    -0.03125,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.015625,
                    -0.0234375,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.09375,
                    -0.1328125,
                    -0.4808769226074219,
                    -0.5403060913085938,
                    -0.5997390747070312,
                    -0.7421875,
                    -0.875,
                    -1.0153923034667969,
                    -0.8125,
                    -0.6171875,
                    -0.4140625,
                    -0.375,
                    -0.3515625,
                    -0.296875,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.109375,
                    -0.109375,
                    -0.1171875,
                    -0.125,
                    -0.125,
                    -0.1328125,
                    -0.1328125,
                    -0.1328125,
                    -0.140625,
                    -0.140625,
                    -0.1484375,
                    -0.1484375,
                    -0.15625,
                    -0.1640625,
                    -0.1640625,
                    -0.1640625,
                    -0.171875,
                    -0.171875,
                    -0.1796875,
                    -0.1875,
                    -0.1953125,
                    -0.1953125,
                    -0.1953125,
                    -0.1953125,
                    -0.203125,
                    -0.203125,
                    -0.203125,
                    -0.203125,
                    -0.203125,
                    -0.203125,
                    -0.203125,
                    -0.1953125,
                    -0.1953125,
                    -0.1875,
                    -0.1875,
                    -0.1796875,
                    -0.1640625,
                    -0.1640625,
                    -0.15625,
                    -0.140625,
                    -0.125,
                    -0.109375,
                    -0.1015625,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.021327972412109375,
                    0.021327972412109375,
                    0.021327972412109375,
                    0.021327972412109375,
                    0.021327972412109375,
                    0.0067577362060546875,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 2506,
                "plot_metadata": {
                    "x_start": 1398,
                    "y_baseline": 610,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "aVL",
                "signal": [
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0859375,
                    -0.1015625,
                    -0.109375,
                    -0.125,
                    -0.140625,
                    -0.1796875,
                    -0.265625,
                    -0.2421875,
                    -0.2578125,
                    -0.265625,
                    -0.2734375,
                    -0.265625,
                    -0.25,
                    -0.234375,
                    -0.21875,
                    -0.2109375,
                    -0.203125,
                    -0.1640625,
                    -0.1328125,
                    -0.1171875,
                    -0.1015625,
                    -0.09375,
                    -0.0859375,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0546875,
                    0.0546875,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.03125,
                    0.015625,
                    0.015625,
                    0.0546875,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0546875,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0390625,
                    0.03125,
                    0.015625,
                    0.015625,
                    0.0390625,
                    0.015625,
                    0.015625,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0546875,
                    -0.078125,
                    -0.09874534606933594,
                    -0.11936569213867188,
                    -0.1399860382080078,
                    -0.1853952407836914,
                    -0.19226837158203125,
                    -0.1953125,
                    -0.1953125,
                    -0.1953125,
                    -0.1953125,
                    -0.1875,
                    -0.1796875,
                    -0.15625,
                    -0.1328125,
                    -0.1171875,
                    -0.1015625,
                    -0.0859375,
                    -0.0703125,
                    -0.0546875,
                    -0.046875,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 2506,
                "plot_metadata": {
                    "x_start": 1398,
                    "y_baseline": 931,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "aVF",
                "signal": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0078125,
                    0.015625,
                    0.2890625,
                    0.40625,
                    0.453125,
                    0.5,
                    0.703125,
                    0.8671875,
                    1.03125,
                    1.203125,
                    1.3671875,
                    1.0625,
                    0.7578125,
                    0.7421875,
                    0.3203125,
                    0.296875,
                    0.21875,
                    0.2109375,
                    0.1796875,
                    0.1484375,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.1640625,
                    0.15625,
                    0.1640625,
                    0.15625,
                    0.15625,
                    0.1484375,
                    0.1484375,
                    0.140625,
                    0.1328125,
                    0.125,
                    0.1171875,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0859375,
                    0.078125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.06205272674560547,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.060006141662597656,
                    -0.043951988220214844,
                    -0.043951988220214844,
                    -0.043951988220214844,
                    -0.043951988220214844,
                    -0.043951988220214844,
                    -0.043951988220214844,
                    -0.02386951446533203,
                    -0.02386951446533203,
                    -0.02819347381591797,
                    -0.032517433166503906,
                    -0.036841392517089844,
                    -0.04116535186767578,
                    -0.04548931121826172,
                    -0.04548931121826172,
                    -0.04548931121826172,
                    -0.04548931121826172,
                    -0.052181243896484375,
                    -0.05887317657470703,
                    -0.05887317657470703,
                    -0.05887317657470703,
                    -0.05887317657470703,
                    -0.04673957824707031,
                    -0.04673957824707031,
                    -0.04673957824707031,
                    -0.04673957824707031,
                    -0.04673957824707031,
                    -0.04673957824707031,
                    -0.030328750610351562,
                    -0.030328750610351562,
                    -0.030328750610351562,
                    -0.030328750610351562,
                    -0.030328750610351562,
                    -0.030328750610351562,
                    -0.030348777770996094,
                    -0.030368804931640625,
                    -0.030388832092285156,
                    -0.030408859252929688,
                    -0.030408859252929688,
                    -0.030287742614746094,
                    -0.030287742614746094,
                    -0.030287742614746094,
                    -0.030257225036621094,
                    -0.030257225036621094,
                    -0.028771400451660156,
                    -0.029349327087402344,
                    -0.02992725372314453,
                    -0.03050518035888672,
                    -0.031083106994628906,
                    -0.025574684143066406,
                    -0.025574684143066406,
                    -0.025574684143066406,
                    -0.032845497131347656,
                    -0.040116310119628906,
                    -0.047387123107910156,
                    -0.054657936096191406,
                    -0.054657936096191406,
                    -0.061881065368652344,
                    -0.059844017028808594,
                    -0.057806968688964844,
                    -0.057806968688964844,
                    -0.057806968688964844,
                    -0.057806968688964844,
                    -0.057806968688964844,
                    -0.06197547912597656,
                    -0.06614398956298828,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0234375,
                    -0.0078125,
                    0.0078125,
                    0.015625,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.3587636947631836,
                    0.6706523895263672,
                    0.8289175033569336,
                    0.9081792831420898,
                    1.3265619277954102,
                    1.1328125,
                    0.9423131942749023,
                    0.9130849838256836,
                    0.9130849838256836,
                    0.5703125,
                    0.546875,
                    0.203125,
                    0.1328125,
                    0.109375,
                    0.1015625,
                    0.0859375,
                    0.0859375,
                    0.078125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1796875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.2109375,
                    0.2109375,
                    0.21875,
                    0.21875,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.21875,
                    0.2109375,
                    0.203125,
                    0.1953125,
                    0.1875,
                    0.1796875,
                    0.171875,
                    0.1640625,
                    0.15625,
                    0.1484375,
                    0.140625,
                    0.1328125,
                    0.125,
                    0.1171875,
                    0.1015625,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0007076263427734375,
                    0.001415252685546875,
                    0.0011005401611328125,
                    0.0011005401611328125,
                    0.00048732757568359375,
                    0.0004978179931640625,
                    0.00058746337890625,
                    0.0006771087646484375,
                    0.00042629241943359375,
                    0.00042629241943359375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.00015163421630859375,
                    0.009065628051757812,
                    0.009065628051757812,
                    0.009065628051757812,
                    0.009065628051757812,
                    0.009065628051757812,
                    0.024286270141601562,
                    0.024286270141601562,
                    0.024286270141601562,
                    0.024286270141601562,
                    0.024286270141601562,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 2506,
                "plot_metadata": {
                    "x_start": 1398,
                    "y_baseline": 1254,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V1",
                "signal": [
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.015625,
                    -0.03125,
                    -0.046875,
                    -0.078125,
                    -0.09375,
                    -0.1015625,
                    -0.1328125,
                    -0.1640625,
                    -0.5741252899169922,
                    -0.9430599212646484,
                    -1.078125,
                    -1.2109375,
                    -1.34375,
                    -1.4765625,
                    -1.28125,
                    -1.078125,
                    -0.8828125,
                    -0.6796875,
                    -0.484375,
                    -0.421875,
                    -0.3046875,
                    -0.21875,
                    -0.203125,
                    -0.1875,
                    -0.1328125,
                    -0.078125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0625,
                    0.171875,
                    0.0625,
                    -0.0546875,
                    -0.1640625,
                    -0.296875,
                    -0.4609375,
                    -0.4765625,
                    -1.140625,
                    -1.1953125,
                    -1.2578125,
                    -1.3125,
                    -1.375,
                    -1.4296875,
                    -1.078125,
                    -0.9921875,
                    -0.46875,
                    -0.4296875,
                    -0.40625,
                    -0.3984375,
                    -0.3125,
                    -0.1328125,
                    -0.1171875,
                    -0.1015625,
                    -0.0703125,
                    -0.046875,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.1484375,
                    0.1484375
                ],
                "sampling_frequency": 320,
                "offset": 5012,
                "plot_metadata": {
                    "x_start": 2200,
                    "y_baseline": 614,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V2",
                "signal": [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    -0.6015625,
                    -0.6015625,
                    -0.6015625,
                    -0.6015625,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0078125,
                    0.0078125,
                    0.03125,
                    0.046875,
                    0.109375,
                    0.1796875,
                    0.2421875,
                    0.0078125,
                    -0.0078125,
                    -0.0390625,
                    -0.109375,
                    -0.27060794830322266,
                    -0.4318408966064453,
                    -0.4296875,
                    -0.4375,
                    -0.4375,
                    -0.4375,
                    -0.4375,
                    -0.4375,
                    -0.4375,
                    -0.4140625,
                    -0.3828125,
                    -0.34375,
                    -0.3046875,
                    -0.3046875,
                    -0.28125,
                    -0.1953125,
                    -0.09375,
                    -0.0703125,
                    -0.0546875,
                    -0.0390625,
                    -0.0234375,
                    -0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.25,
                    0.25,
                    0.25,
                    0.25,
                    0.2578125,
                    0.25,
                    0.25,
                    0.2421875,
                    0.234375,
                    0.2265625,
                    0.21875,
                    0.2109375,
                    0.203125,
                    0.1953125,
                    0.1875,
                    0.1796875,
                    0.171875,
                    0.15625,
                    0.140625,
                    0.1171875,
                    0.109375,
                    0.09375,
                    0.078125,
                    0.0625,
                    0.0390625,
                    0.0390625,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    -0.0078125,
                    0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.2265625,
                    0.015625,
                    -0.125,
                    -0.2109375,
                    -0.5703125,
                    -0.578125,
                    -1.4921875,
                    -1.5546875,
                    -1.609375,
                    -1.671875,
                    -1.7265625,
                    -1.7890625,
                    -1.4921875,
                    -1.4765625,
                    -0.765625,
                    -0.75,
                    -0.671875,
                    -0.671875,
                    -0.265625,
                    -0.265625,
                    -0.15625,
                    -0.1328125,
                    -0.1171875,
                    -0.0859375,
                    -0.0625,
                    -0.046875,
                    -0.0234375,
                    -0.015625,
                    0.0,
                    0.0078125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.09375,
                    0.078125,
                    0.0859375,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375
                ],
                "sampling_frequency": 320,
                "offset": 5012,
                "plot_metadata": {
                    "x_start": 2200,
                    "y_baseline": 932,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V3",
                "signal": [
                    null,
                    -0.0078125,
                    -0.007740974426269531,
                    -0.007740974426269531,
                    -0.005035400390625,
                    -0.005035400390625,
                    -0.004513740539550781,
                    -0.004513740539550781,
                    -0.004513740539550781,
                    -0.004166603088378906,
                    -0.004166603088378906,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    0.0021352767944335938,
                    -0.0011806488037109375,
                    -0.004496574401855469,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0078125,
                    0.0234375,
                    0.0546875,
                    0.1015625,
                    0.1328125,
                    0.1796875,
                    0.234375,
                    0.1328125,
                    0.09375,
                    0.0234375,
                    -0.125,
                    -0.2890625,
                    -0.37740325927734375,
                    -0.4292182922363281,
                    -0.4777793884277344,
                    -0.6328125,
                    -0.7890625,
                    -0.9453125,
                    -1.1015625,
                    -1.2540740966796875,
                    -0.46875,
                    -0.46875,
                    -0.40625,
                    -0.3828125,
                    -0.3515625,
                    -0.3359375,
                    -0.2421875,
                    -0.2109375,
                    -0.1171875,
                    -0.109375,
                    -0.09375,
                    -0.0859375,
                    -0.0625,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.1953125,
                    0.203125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.2578125,
                    0.25,
                    0.25,
                    0.2421875,
                    0.234375,
                    0.21875,
                    0.2109375,
                    0.1953125,
                    0.1796875,
                    0.171875,
                    0.15625,
                    0.1484375,
                    0.140625,
                    0.1328125,
                    0.1171875,
                    0.1015625,
                    0.09375,
                    0.078125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.046875,
                    0.1015625,
                    0.1484375,
                    0.1953125,
                    0.25,
                    -0.015625,
                    -0.0390625,
                    -0.0625,
                    -0.2265625,
                    -0.2421875,
                    -0.6484375,
                    -0.671875,
                    -0.921875,
                    -0.9921875,
                    -1.0546875,
                    -1.125,
                    -1.1875,
                    -1.2578125,
                    -0.8046875,
                    -0.7109375,
                    -0.265625,
                    -0.1875,
                    -0.1640625,
                    -0.1484375,
                    -0.125,
                    -0.1015625,
                    -0.078125,
                    -0.0703125,
                    -0.046875,
                    -0.03125,
                    -0.015625,
                    -0.0078125,
                    0.0078125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.125,
                    0.09375,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875
                ],
                "sampling_frequency": 320,
                "offset": 5012,
                "plot_metadata": {
                    "x_start": 2200,
                    "y_baseline": 1249,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V4",
                "signal": [
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.34375,
                    0.359375,
                    0.3671875,
                    0.3828125,
                    0.390625,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.4609375,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.4921875,
                    0.4921875,
                    0.5,
                    0.5,
                    0.5,
                    0.5078125,
                    0.5078125,
                    0.5,
                    0.5,
                    0.5,
                    0.5,
                    0.4921875,
                    0.4921875,
                    0.484375,
                    0.46875,
                    0.4609375,
                    0.453125,
                    0.453125,
                    0.4296875,
                    0.3984375,
                    0.3828125,
                    0.3671875,
                    0.34375,
                    0.3359375,
                    0.3125,
                    0.296875,
                    0.28125,
                    0.265625,
                    0.25,
                    0.2421875,
                    0.1875,
                    0.1796875,
                    0.1640625,
                    0.140625,
                    0.125,
                    0.109375,
                    0.09375,
                    0.09375,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0546875,
                    0.0703125,
                    0.1015625,
                    0.1171875,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.140625,
                    0.140625,
                    0.140625,
                    -0.27626800537109375,
                    -0.27626800537109375,
                    -0.2597637176513672,
                    -0.27626800537109375,
                    -0.46398162841796875,
                    -0.46398162841796875,
                    -1.3671875,
                    -1.1484375,
                    -0.9375,
                    -0.7265625,
                    -0.5078125,
                    -0.515625,
                    -0.0703125,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0078125,
                    0.0234375,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.1953125,
                    0.2109375,
                    0.21875,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.2734375,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.3359375,
                    0.34375,
                    0.3515625,
                    0.3515625,
                    0.3671875,
                    0.375,
                    0.3828125,
                    0.3984375,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.46875,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.484375,
                    0.4921875,
                    0.5,
                    0.5,
                    0.5,
                    0.4921875,
                    0.5,
                    0.4921875,
                    0.4765625,
                    0.46875,
                    0.453125,
                    0.4296875,
                    0.4140625,
                    0.40625,
                    0.390625,
                    0.375,
                    0.359375,
                    0.34375,
                    0.3203125,
                    0.2890625,
                    0.265625,
                    0.2421875,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.171875,
                    0.1640625,
                    0.1484375,
                    0.125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.031126022338867188,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.023189544677734375,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.015625,
                    -0.015625,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 7518,
                "plot_metadata": {
                    "x_start": 3002,
                    "y_baseline": 627,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V5",
                "signal": [
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.296875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.3515625,
                    0.3671875,
                    0.3671875,
                    0.375,
                    0.3828125,
                    0.3984375,
                    0.3984375,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4375,
                    0.4453125,
                    0.4609375,
                    0.4609375,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.46875,
                    0.4609375,
                    0.4453125,
                    0.4453125,
                    0.4375,
                    0.421875,
                    0.3984375,
                    0.3828125,
                    0.3671875,
                    0.34375,
                    0.3203125,
                    0.296875,
                    0.2734375,
                    0.25,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.1875,
                    0.1796875,
                    0.15625,
                    0.140625,
                    0.125,
                    0.1171875,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0078125,
                    0.0078125,
                    0.078125,
                    0.0546875,
                    2.1848487854003906,
                    1.953125,
                    1.71875,
                    1.484375,
                    1.25,
                    1.0136356353759766,
                    1.0136356353759766,
                    0.3189735412597656,
                    0.3189735412597656,
                    0.2649555206298828,
                    0.2109375,
                    0.0859375,
                    -0.0078125,
                    -0.1328125,
                    -0.2578125,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.234375,
                    0.2421875,
                    0.2578125,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.2890625,
                    0.296875,
                    0.296875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.3359375,
                    0.3515625,
                    0.375,
                    0.390625,
                    0.390625,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.453125,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.484375,
                    0.484375,
                    0.484375,
                    0.484375,
                    0.484375,
                    0.484375,
                    0.4765625,
                    0.4609375,
                    0.453125,
                    0.4375,
                    0.421875,
                    0.40625,
                    0.390625,
                    0.375,
                    0.359375,
                    0.3359375,
                    0.3125,
                    0.2890625,
                    0.265625,
                    0.2421875,
                    0.2109375,
                    0.1796875,
                    0.1640625,
                    0.140625,
                    0.125,
                    0.109375,
                    0.09375,
                    0.078125,
                    0.0703125,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03521537780761719,
                    -0.03521537780761719,
                    -0.03515815734863281,
                    -0.03489971160888672,
                    -0.03489971160888672,
                    -0.03489971160888672,
                    -0.03489971160888672,
                    -0.034697532653808594,
                    -0.034697532653808594,
                    -0.034697532653808594,
                    -0.03478240966796875,
                    -0.03307819366455078,
                    -0.03137397766113281,
                    -0.029669761657714844,
                    -0.027965545654296875,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.026261329650878906,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 7518,
                "plot_metadata": {
                    "x_start": 3002,
                    "y_baseline": 944,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V6",
                "signal": [
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.203125,
                    0.2109375,
                    0.2265625,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.2578125,
                    0.2734375,
                    0.28125,
                    0.3046875,
                    0.3203125,
                    0.3203125,
                    0.328125,
                    0.3359375,
                    0.3515625,
                    0.3515625,
                    0.3671875,
                    0.3671875,
                    0.3828125,
                    0.390625,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4375,
                    0.4375,
                    0.4453125,
                    0.4375,
                    0.4375,
                    0.4375,
                    0.4296875,
                    0.421875,
                    0.4140625,
                    0.3984375,
                    0.3828125,
                    0.375,
                    0.3515625,
                    0.34375,
                    0.3203125,
                    0.3046875,
                    0.2890625,
                    0.2734375,
                    0.2578125,
                    0.2421875,
                    0.2265625,
                    0.2109375,
                    0.1953125,
                    0.1796875,
                    0.1640625,
                    0.1484375,
                    0.140625,
                    0.1328125,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.03125,
                    0.03125,
                    0.0390625,
                    0.0703125,
                    0.08477401733398438,
                    0.09923553466796875,
                    0.1015625,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09142208099365234,
                    0.08693218231201172,
                    0.03125,
                    0.0390625,
                    0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.25,
                    0.265625,
                    0.28125,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.328125,
                    0.328125,
                    0.3359375,
                    0.34375,
                    0.359375,
                    0.3671875,
                    0.375,
                    0.3828125,
                    0.390625,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.3984375,
                    0.390625,
                    0.375,
                    0.359375,
                    0.3515625,
                    0.3359375,
                    0.3203125,
                    0.296875,
                    0.2734375,
                    0.25,
                    0.234375,
                    0.203125,
                    0.1875,
                    0.171875,
                    0.15625,
                    0.1328125,
                    0.125,
                    0.109375,
                    0.1015625,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03490161895751953,
                    -0.030740737915039062,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.026579856872558594,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 7518,
                "plot_metadata": {
                    "x_start": 3002,
                    "y_baseline": 1262,
                    "mv_rate": 128
                }
            }
        ],
        "rhythm_leads": [
            {
                "version": "1.0",
                "lead_name": "V1",
                "signal": [
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    -0.04721832275390625,
                    -0.1569366455078125,
                    -0.1569366455078125,
                    -0.1734943389892578,
                    -0.6984167098999023,
                    -0.8636589050292969,
                    -0.95782470703125,
                    -1.0519905090332031,
                    -1.0519905090332031,
                    -1.5390625,
                    -2.0214900970458984,
                    -1.75,
                    -1.46875,
                    -1.1953125,
                    -0.9235630035400391,
                    -0.7641458511352539,
                    -0.6047286987304688,
                    -0.4453125,
                    -0.421875,
                    -0.2578125,
                    -0.140625,
                    -0.109375,
                    -0.09375,
                    -0.0625,
                    -0.0390625,
                    -0.015625,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0625,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.015625,
                    0.0,
                    -0.39476776123046875,
                    -0.4421253204345703,
                    -0.7578125,
                    -1.078125,
                    -1.453125,
                    -1.8359375,
                    -2.2109375,
                    -1.890625,
                    -1.578125,
                    -1.2578125,
                    -1.109375,
                    -0.953125,
                    -0.8046875,
                    -0.6506309509277344,
                    -0.5148200988769531,
                    -0.2265625,
                    -0.2109375,
                    -0.203125,
                    -0.1796875,
                    -0.078125,
                    -0.0625,
                    -0.046875,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.078125,
                    0.125,
                    0.1640625,
                    -0.1875,
                    -0.296875,
                    -0.3125,
                    -0.328125,
                    -0.34375,
                    -0.390625,
                    -1.3046875,
                    -2.21875,
                    -1.09375,
                    -1.0859375,
                    -1.0546875,
                    -1.0546875,
                    -1.0546875,
                    -0.65625,
                    -0.546875,
                    -0.4921875,
                    -0.328125,
                    -0.296875,
                    -0.140625,
                    -0.1171875,
                    -0.09375,
                    -0.0625,
                    -0.0390625,
                    -0.0234375,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03183937072753906,
                    0.032166481018066406,
                    0.03249359130859375,
                    0.032820701599121094,
                    0.03314781188964844,
                    0.03347492218017578,
                    0.0335845947265625,
                    0.03369331359863281,
                    0.033802032470703125,
                    0.033911705017089844,
                    0.03402137756347656,
                    0.034130096435546875,
                    0.034130096435546875,
                    0.025112152099609375,
                    0.01609516143798828,
                    0.01609516143798828,
                    0.01609516143798828,
                    0.01609516143798828,
                    0.009860992431640625,
                    0.009860992431640625,
                    0.009860992431640625,
                    0.0066089630126953125,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    7.62939453125e-06,
                    0.0001239776611328125,
                    0.0001239776611328125,
                    0.0001239776611328125,
                    0.0001239776611328125,
                    0.0001239776611328125,
                    0.0001239776611328125,
                    0.000301361083984375,
                    0.000789642333984375,
                    0.015903472900390625,
                    0.015903472900390625,
                    0.015903472900390625,
                    0.015903472900390625,
                    0.017559051513671875,
                    0.03163719177246094,
                    0.03163719177246094,
                    0.03163719177246094,
                    0.03163719177246094,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0703125,
                    0.078125,
                    0.0703125,
                    0.0546875,
                    0.0,
                    -0.3869476318359375,
                    -0.7738962173461914,
                    -0.7738962173461914,
                    -1.0625,
                    -1.34375,
                    -1.6328125,
                    -1.921875,
                    -2.204294204711914,
                    -0.84375,
                    -0.8359375,
                    -0.828125,
                    -0.7734375,
                    -0.3515625,
                    -0.3359375,
                    -0.328125,
                    -0.1953125,
                    -0.1171875,
                    -0.0859375,
                    -0.0703125,
                    -0.0546875,
                    -0.0390625,
                    -0.03125,
                    -0.015625,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    -0.015207290649414062,
                    null,
                    null,
                    -0.01540374755859375,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015574455261230469,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.01561737060546875,
                    -0.015554428100585938,
                    -0.015554428100585938,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    -0.0076503753662109375,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    -0.046875,
                    -0.09375,
                    -0.09375,
                    -0.1171875,
                    -0.1875,
                    -1.015625,
                    -1.015625,
                    -1.015625,
                    -1.328125,
                    -1.6328125,
                    -1.943526268005371,
                    -1.104842185974121,
                    -1.0921630859375,
                    -1.0921630859375,
                    -0.6953125,
                    -0.6640625,
                    -0.3828125,
                    -0.3125,
                    -0.1796875,
                    -0.1640625,
                    -0.125,
                    -0.1015625,
                    -0.0546875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0546875,
                    0.109375,
                    0.1640625,
                    0.0703125,
                    -0.171875,
                    -0.4453125,
                    -0.484375,
                    -0.7890625,
                    -0.8203125,
                    -0.8125,
                    -0.8203125,
                    -1.0390625,
                    -1.265625,
                    -1.484375,
                    -1.7109375,
                    -1.9296875,
                    -0.46875,
                    -0.4453125,
                    -0.4296875,
                    -0.4140625,
                    -0.3984375,
                    -0.1484375,
                    -0.1328125,
                    -0.1171875,
                    -0.09375,
                    -0.0625,
                    -0.046875,
                    -0.03125,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.046875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0546875,
                    0.0234375,
                    -0.3359375,
                    -0.6464099884033203,
                    -0.8356094360351562,
                    -0.8356094360351562,
                    -0.8891048431396484,
                    -1.7070074081420898,
                    -1.53125,
                    -1.359375,
                    -1.1875,
                    -1.015625,
                    -0.8445148468017578,
                    -0.5234375,
                    -0.4921875,
                    -0.46875,
                    -0.1953125,
                    -0.1796875,
                    -0.1328125,
                    -0.109375,
                    -0.09375,
                    -0.0859375,
                    -0.0703125,
                    -0.0625,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03119945526123047,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031148910522460938,
                    -0.031099319458007812,
                    -0.031099319458007812,
                    -0.031099319458007812,
                    -0.031099319458007812,
                    -0.031099319458007812,
                    -0.030892372131347656,
                    -0.03095531463623047,
                    -0.030870437622070312,
                    -0.03089141845703125,
                    -0.030885696411132812,
                    -0.030885696411132812,
                    -0.030885696411132812,
                    -0.030885696411132812,
                    -0.029033660888671875,
                    -0.030731201171875,
                    -0.030731201171875,
                    -0.030731201171875,
                    -0.030731201171875,
                    -0.030731201171875,
                    -0.029297828674316406,
                    -0.027864456176757812,
                    -0.02904510498046875,
                    -0.031179428100585938,
                    -0.03112030029296875,
                    -0.030979156494140625,
                    -0.030838966369628906,
                    -0.030838966369628906,
                    -0.030838966369628906,
                    -0.030838966369628906,
                    -0.02880096435546875,
                    -0.026762008666992188,
                    -0.024723052978515625,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.02268505096435547,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.051990509033203125,
                    -0.041481971740722656,
                    -0.0467681884765625,
                    -0.0467681884765625,
                    -0.0467681884765625,
                    -0.038924217224121094,
                    -0.031080245971679688,
                    -0.031080245971679688,
                    -0.031080245971679688,
                    -0.03474998474121094,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 1559,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "II",
                "signal": [
                    null,
                    0.0078125,
                    0.0078125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    0.021056175231933594,
                    0.08117485046386719,
                    0.1953125,
                    0.3125,
                    0.4260597229003906,
                    0.2578125,
                    0.08411407470703125,
                    0.04104137420654297,
                    0.026961326599121094,
                    0.012882232666015625,
                    0.012882232666015625,
                    0.012882232666015625,
                    0.012882232666015625,
                    0.06857013702392578,
                    0.0792856216430664,
                    0.09937858581542969,
                    0.21875,
                    0.3426513671875,
                    0.109375,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.28125,
                    0.2890625,
                    0.2890625,
                    0.2890625,
                    0.296875,
                    0.3046875,
                    0.3125,
                    0.3125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3125,
                    0.3046875,
                    0.296875,
                    0.2890625,
                    0.28125,
                    0.2734375,
                    0.2578125,
                    0.2421875,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.1875,
                    0.171875,
                    0.1640625,
                    0.1484375,
                    0.1328125,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0234375,
                    0.03125,
                    0.0859375,
                    0.28125,
                    0.4296875,
                    0.7197027206420898,
                    0.7651882171630859,
                    0.9296875,
                    1.09375,
                    1.2578125,
                    1.4242496490478516,
                    1.4242496490478516,
                    1.2734375,
                    1.125,
                    0.9765625,
                    0.828125,
                    0.671875,
                    0.5234375,
                    0.3743162155151367,
                    0.24575233459472656,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.296875,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3203125,
                    0.3125,
                    0.3046875,
                    0.296875,
                    0.28125,
                    0.2734375,
                    0.2578125,
                    0.2421875,
                    0.2265625,
                    0.21875,
                    0.2109375,
                    0.1953125,
                    0.1875,
                    0.1796875,
                    0.1640625,
                    0.1484375,
                    0.1328125,
                    0.125,
                    0.109375,
                    0.1015625,
                    0.1015625,
                    0.09375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.015625,
                    0.0078125,
                    0.015625,
                    0.015625,
                    -0.015625,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.2265625,
                    -0.09375,
                    0.03125,
                    0.546875,
                    1.0625,
                    0.6015625,
                    0.1484375,
                    0.1328125,
                    0.109375,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.2109375,
                    0.21875,
                    0.21875,
                    0.21875,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.2265625,
                    0.21875,
                    0.2109375,
                    0.203125,
                    0.203125,
                    0.1875,
                    0.1796875,
                    0.171875,
                    0.1640625,
                    0.15625,
                    0.140625,
                    0.1328125,
                    0.1171875,
                    0.1015625,
                    0.09375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0390625,
                    0.015625,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.07763290405273438,
                    -0.062102317810058594,
                    -0.062102317810058594,
                    -0.062102317810058594,
                    -0.062102317810058594,
                    -0.062102317810058594,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.015625,
                    0.0234375,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0703125,
                    0.171875,
                    0.2671775817871094,
                    0.035556793212890625,
                    0.005501747131347656,
                    0.005501747131347656,
                    0.005501747131347656,
                    0.005501747131347656,
                    0.005501747131347656,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0390625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.234375,
                    0.2421875,
                    0.2421875,
                    0.25,
                    0.25,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.265625,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.265625,
                    0.2578125,
                    0.25,
                    0.25,
                    0.2421875,
                    0.234375,
                    0.234375,
                    0.2265625,
                    0.2109375,
                    0.1953125,
                    0.1875,
                    0.1796875,
                    0.1640625,
                    0.15625,
                    0.1484375,
                    0.125,
                    0.1171875,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    null,
                    null,
                    -0.014534950256347656,
                    -0.014650344848632812,
                    -0.014765739440917969,
                    -0.014881134033203125,
                    -0.014995574951171875,
                    -0.015110969543457031,
                    -0.015226364135742188,
                    -0.015340805053710938,
                    -0.015456199645996094,
                    -0.01557159423828125,
                    -0.011820793151855469,
                    -0.011820793151855469,
                    -0.011820793151855469,
                    -0.011820793151855469,
                    -0.020900726318359375,
                    -0.029981613159179688,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.054576873779296875,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.0529632568359375,
                    -0.06157493591308594,
                    -0.07018661499023438,
                    -0.07018661499023438,
                    -0.07018661499023438,
                    -0.07018661499023438,
                    -0.07806205749511719,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.109375,
                    -0.109375,
                    -0.1171875,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.1171875,
                    -0.125,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.125,
                    -0.125,
                    -0.1171875,
                    -0.125,
                    -0.125,
                    -0.125,
                    -0.1171875,
                    -0.1171875,
                    -0.125,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.1328125,
                    -0.109375,
                    -0.1328125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.15625,
                    0.265625,
                    0.3828125,
                    0.4921875,
                    0.6063747406005859,
                    0.2109375,
                    0.1953125,
                    0.09375,
                    0.046875,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.21875,
                    0.2265625,
                    0.2265625,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.2421875,
                    0.234375,
                    0.234375,
                    0.234375,
                    0.2265625,
                    0.21875,
                    0.203125,
                    0.1953125,
                    0.1796875,
                    0.15625,
                    0.140625,
                    0.125,
                    0.1171875,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.0390625,
                    0.015625,
                    0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.09375,
                    -0.1015625,
                    -0.109375,
                    -0.109375,
                    -0.109375,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.1171875,
                    -0.109375,
                    -0.109375,
                    -0.1015625,
                    -0.1015625,
                    -0.09375,
                    -0.09375,
                    -0.078125,
                    -0.0625,
                    -0.0546875,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    0.0,
                    0.0078125,
                    0.0234375,
                    0.046875,
                    0.140625,
                    0.0859375,
                    0.03125,
                    0.0078125,
                    0.0078125,
                    -0.203125,
                    -0.0546875,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.2109375,
                    0.21875,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.2421875,
                    0.25,
                    0.25,
                    0.2578125,
                    0.2578125,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.2734375,
                    0.265625,
                    0.2578125,
                    0.25,
                    0.2421875,
                    0.234375,
                    0.2265625,
                    0.21875,
                    0.203125,
                    0.1953125,
                    0.171875,
                    0.15625,
                    0.1484375,
                    0.1328125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.046875,
                    0.03125,
                    0.0234375,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    0.0,
                    0.0078125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.08905220031738281,
                    0.09806251525878906,
                    0.10707378387451172,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1015625,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.046875,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.25,
                    0.25,
                    0.2578125,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.3359375,
                    0.34375,
                    0.34375,
                    0.3515625,
                    0.3515625,
                    0.3515625,
                    0.3515625,
                    0.34375,
                    0.3359375,
                    0.3359375,
                    0.328125,
                    0.3203125,
                    0.3125,
                    0.296875,
                    0.2890625,
                    0.28125,
                    0.265625,
                    0.25,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.1875,
                    0.171875,
                    0.1640625,
                    0.1484375,
                    0.140625,
                    0.1328125,
                    0.1171875,
                    0.1171875,
                    0.109375,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.02536487579345703,
                    0.03510475158691406,
                    0.03510475158691406,
                    0.03510475158691406,
                    0.03510475158691406,
                    0.03510475158691406,
                    0.03510475158691406,
                    0.03465080261230469,
                    0.03595733642578125,
                    0.03888702392578125,
                    0.04181671142578125,
                    0.04474639892578125,
                    0.04767608642578125,
                    0.05060577392578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.05353546142578125,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.04694175720214844,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.078125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 1887,
                    "mv_rate": 128
                }
            },
            {
                "version": "1.0",
                "lead_name": "V5",
                "signal": [
                    null,
                    null,
                    null,
                    -0.0003986358642578125,
                    -0.0041046142578125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0234375,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0703125,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.09375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.0859375,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.1953125,
                    0.2109375,
                    0.21875,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.2890625,
                    0.3046875,
                    0.3203125,
                    0.328125,
                    0.3359375,
                    0.3515625,
                    0.359375,
                    0.3671875,
                    0.375,
                    0.3828125,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.453125,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.453125,
                    0.453125,
                    0.4453125,
                    0.4375,
                    0.421875,
                    0.40625,
                    0.390625,
                    0.375,
                    0.359375,
                    0.3359375,
                    0.3125,
                    0.296875,
                    0.2734375,
                    0.25,
                    0.234375,
                    0.21875,
                    0.203125,
                    0.1796875,
                    0.1484375,
                    0.1328125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0703125,
                    -0.0703125,
                    -0.0546875,
                    -0.046875,
                    0.4609375,
                    0.8362693786621094,
                    0.989349365234375,
                    1.1424312591552734,
                    1.59375,
                    2.0390625,
                    2.4875307083129883,
                    1.96875,
                    1.453125,
                    0.9367656707763672,
                    0.8875961303710938,
                    0.8474655151367188,
                    0.8073348999023438,
                    0.7672023773193359,
                    0.421875,
                    0.0859375,
                    -0.2578125,
                    -0.1796875,
                    -0.1015625,
                    -0.0625,
                    -0.03125,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.1953125,
                    0.1953125,
                    0.203125,
                    0.203125,
                    0.203125,
                    0.21875,
                    0.2265625,
                    0.2265625,
                    0.2421875,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.296875,
                    0.3125,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.3515625,
                    0.359375,
                    0.375,
                    0.390625,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4375,
                    0.4453125,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.46875,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4453125,
                    0.4375,
                    0.421875,
                    0.3984375,
                    0.390625,
                    0.3671875,
                    0.359375,
                    0.3359375,
                    0.3125,
                    0.296875,
                    0.2734375,
                    0.2421875,
                    0.2265625,
                    0.2109375,
                    0.1796875,
                    0.15625,
                    0.140625,
                    0.125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.0859375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.09375,
                    -0.0859375,
                    -0.0703125,
                    0.3125,
                    0.3984375,
                    0.4296875,
                    0.6484375,
                    1.0390625,
                    1.421875,
                    1.8046875,
                    2.1953125,
                    0.7890625,
                    0.7734375,
                    0.765625,
                    0.1796875,
                    0.0625,
                    -0.015625,
                    -0.03125,
                    -0.1484375,
                    -0.2578125,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.2109375,
                    0.2265625,
                    0.2421875,
                    0.25,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.296875,
                    0.3046875,
                    0.3203125,
                    0.328125,
                    0.34375,
                    0.3515625,
                    0.359375,
                    0.375,
                    0.3828125,
                    0.390625,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4296875,
                    0.4375,
                    0.4375,
                    0.4453125,
                    0.4453125,
                    0.4453125,
                    0.4453125,
                    0.453125,
                    0.453125,
                    0.4453125,
                    0.4375,
                    0.4296875,
                    0.4140625,
                    0.3984375,
                    0.3828125,
                    0.3671875,
                    0.34375,
                    0.3125,
                    0.2890625,
                    0.2734375,
                    0.25,
                    0.2265625,
                    0.203125,
                    0.1796875,
                    0.1640625,
                    0.1484375,
                    0.1328125,
                    0.1171875,
                    0.1015625,
                    0.0859375,
                    0.0703125,
                    0.0625,
                    0.046875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.000286102294921875,
                    0.0006122589111328125,
                    0.0008220672607421875,
                    0.0008220672607421875,
                    0.003078460693359375,
                    0.003078460693359375,
                    -0.0061702728271484375,
                    -0.01541900634765625,
                    -0.01541900634765625,
                    -0.01541900634765625,
                    -0.0094451904296875,
                    -0.02034759521484375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    0.015625,
                    0.125,
                    0.1328125,
                    0.296875,
                    0.3046875,
                    0.3395347595214844,
                    0.34537506103515625,
                    0.3671875,
                    0.390625,
                    0.4140625,
                    0.484375,
                    0.56182861328125,
                    0.4149589538574219,
                    0.3828125,
                    0.3125,
                    0.2890625,
                    0.21875,
                    0.1640625,
                    0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0078125,
                    -0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.203125,
                    0.203125,
                    0.2109375,
                    0.2109375,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.2578125,
                    0.2734375,
                    0.28125,
                    0.28125,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.34375,
                    0.3515625,
                    0.359375,
                    0.3671875,
                    0.390625,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.453125,
                    0.4609375,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.484375,
                    0.484375,
                    0.4765625,
                    0.4765625,
                    0.46875,
                    0.46875,
                    0.4609375,
                    0.4453125,
                    0.4375,
                    0.421875,
                    0.40625,
                    0.3828125,
                    0.359375,
                    0.3359375,
                    0.3203125,
                    0.296875,
                    0.2734375,
                    0.2421875,
                    0.2265625,
                    0.203125,
                    0.171875,
                    0.1484375,
                    0.125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.0703125,
                    0.0546875,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null,
                    null,
                    -0.0058116912841796875,
                    -0.0064144134521484375,
                    -0.007266998291015625,
                    -0.007266998291015625,
                    -0.007266998291015625,
                    -0.007266998291015625,
                    -0.007266998291015625,
                    -0.007266998291015625,
                    -0.022489547729492188,
                    -0.022489547729492188,
                    -0.018718719482421875,
                    -0.028890609741210938,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0859375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.078125,
                    0.0,
                    0.2109375,
                    0.203125,
                    0.1875,
                    0.1875,
                    0.8046875,
                    1.2265625,
                    1.6484375,
                    2.0625,
                    2.485910415649414,
                    0.7936801910400391,
                    0.7936801910400391,
                    -0.0078125,
                    -0.0703125,
                    -0.1328125,
                    -0.1953125,
                    -0.2578125,
                    -0.1015625,
                    -0.0703125,
                    -0.0625,
                    -0.0546875,
                    -0.0390625,
                    -0.0234375,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.1171875,
                    0.125,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.1328125,
                    0.140625,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.2109375,
                    0.2265625,
                    0.234375,
                    0.25,
                    0.265625,
                    0.2734375,
                    0.28125,
                    0.28125,
                    0.2890625,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.34375,
                    0.359375,
                    0.3671875,
                    0.390625,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4375,
                    0.4375,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.453125,
                    0.4453125,
                    0.4375,
                    0.4296875,
                    0.4140625,
                    0.390625,
                    0.3671875,
                    0.3515625,
                    0.328125,
                    0.3046875,
                    0.2890625,
                    0.2734375,
                    0.2578125,
                    0.2421875,
                    0.21875,
                    0.203125,
                    0.1875,
                    0.1640625,
                    0.1328125,
                    0.109375,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.046875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.046875,
                    -0.046875,
                    0.0078125,
                    0.015625,
                    0.03125,
                    0.1171875,
                    0.109375,
                    0.109375,
                    0.1171875,
                    0.2265625,
                    0.3359375,
                    0.453125,
                    0.5625,
                    0.671875,
                    0.296875,
                    0.2890625,
                    0.2734375,
                    0.25,
                    -0.015625,
                    -0.1015625,
                    -0.1796875,
                    -0.265625,
                    -0.0078125,
                    -0.015625,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.046875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.1171875,
                    0.125,
                    0.1328125,
                    0.1328125,
                    0.1484375,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.171875,
                    0.1796875,
                    0.1875,
                    0.1953125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.2421875,
                    0.2578125,
                    0.265625,
                    0.2734375,
                    0.296875,
                    0.3046875,
                    0.3125,
                    0.3203125,
                    0.328125,
                    0.34375,
                    0.3515625,
                    0.3671875,
                    0.3828125,
                    0.3984375,
                    0.40625,
                    0.4140625,
                    0.421875,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.4453125,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.4609375,
                    0.453125,
                    0.4453125,
                    0.4453125,
                    0.4375,
                    0.421875,
                    0.40625,
                    0.390625,
                    0.375,
                    0.3515625,
                    0.328125,
                    0.3046875,
                    0.28125,
                    0.2578125,
                    0.2421875,
                    0.2109375,
                    0.1875,
                    0.171875,
                    0.1484375,
                    0.1328125,
                    0.1171875,
                    0.1015625,
                    0.09375,
                    0.0859375,
                    0.078125,
                    0.0703125,
                    0.0625,
                    0.0546875,
                    0.0390625,
                    0.0390625,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.015625,
                    -0.015625,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0078125,
                    0.015625,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.078125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0546875,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.046875,
                    -0.03125,
                    -0.0078125,
                    0.0,
                    0.078125,
                    0.0546875,
                    1.4309148788452148,
                    1.4309148788452148,
                    1.4309148788452148,
                    1.7734375,
                    2.125,
                    2.468494415283203,
                    1.4331178665161133,
                    1.2538785934448242,
                    0.8763961791992188,
                    0.4375,
                    0.4375,
                    0.0625,
                    -0.3125,
                    -0.25,
                    -0.1796875,
                    -0.1171875,
                    -0.0546875,
                    -0.046875,
                    -0.0390625,
                    -0.0234375,
                    -0.0078125,
                    0.0,
                    0.0078125,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.015625,
                    0.015625,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0234375,
                    0.0234375,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.03125,
                    0.0390625,
                    0.046875,
                    0.0546875,
                    0.0546875,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0625,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.0703125,
                    0.078125,
                    0.078125,
                    0.0859375,
                    0.0859375,
                    0.09375,
                    0.09375,
                    0.09375,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.1015625,
                    0.109375,
                    0.109375,
                    0.125,
                    0.125,
                    0.1328125,
                    0.140625,
                    0.1484375,
                    0.15625,
                    0.15625,
                    0.1640625,
                    0.1640625,
                    0.171875,
                    0.1796875,
                    0.1796875,
                    0.1875,
                    0.1875,
                    0.203125,
                    0.203125,
                    0.2109375,
                    0.21875,
                    0.2265625,
                    0.234375,
                    0.2421875,
                    0.25,
                    0.2578125,
                    0.265625,
                    0.28125,
                    0.296875,
                    0.3125,
                    0.3203125,
                    0.3359375,
                    0.3515625,
                    0.359375,
                    0.3671875,
                    0.375,
                    0.390625,
                    0.40625,
                    0.4140625,
                    0.4296875,
                    0.4375,
                    0.4453125,
                    0.453125,
                    0.4609375,
                    0.4609375,
                    0.46875,
                    0.46875,
                    0.4765625,
                    0.4765625,
                    0.4765625,
                    0.46875,
                    0.4609375,
                    0.453125,
                    0.4453125,
                    0.4453125,
                    0.4296875,
                    0.4140625,
                    0.40625,
                    0.390625,
                    0.375,
                    0.359375,
                    0.328125,
                    0.3125,
                    0.2890625,
                    0.265625,
                    0.234375,
                    0.21875,
                    0.1875,
                    0.1640625,
                    0.140625,
                    0.125,
                    0.1015625,
                    0.078125,
                    0.0625,
                    0.0546875,
                    0.0390625,
                    0.03125,
                    0.0234375,
                    0.015625,
                    0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.0078125,
                    -0.015625,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.078125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0703125,
                    -0.0625,
                    -0.0625,
                    -0.0625,
                    -0.0546875,
                    -0.0546875,
                    -0.0390625,
                    -0.03125,
                    -0.03125,
                    -0.0234375,
                    -0.0078125,
                    -0.0078125,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    -0.0078125,
                    -0.015625,
                    -0.0234375,
                    -0.0234375,
                    -0.0234375,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.03125,
                    -0.0390625,
                    -0.0390625,
                    -0.046875,
                    -0.046875,
                    -0.0546875,
                    -0.0625,
                    -0.0703125,
                    -0.078125,
                    -0.0859375,
                    -0.0859375,
                    -0.09375,
                    -0.09375,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    -0.1015625,
                    null,
                    null
                ],
                "sampling_frequency": 320,
                "offset": 0,
                "plot_metadata": {
                    "x_start": 596,
                    "y_baseline": 2203,
                    "mv_rate": 128
                }
            }
        ],
        "metadata": {
            "ecg_metadata": {
                "number_of_photos": 1,
                "index_of_photo": 0,
                "paper_speed": 25,
                "voltage_gain": 10,
                "photo_rotation": 0,
                "ecg_layout": "3x4_r3",
                "lead_order": "standard"
            },
            "prob": 0.7215694230523642
        }
    },
    "suggestion": null
}