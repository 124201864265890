import React, { useState, useRef, useEffect } from 'react';

export default function ImageUploader({forwardImageHere}) {
    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);
    const dropAreaRef = useRef(null);

    const handleFiles = (files) => {
        const file = files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            console.log(file);
            reader.onloadend = () => {
                setImage(reader.result);
                console.log(reader.result);
                forwardImageHere(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const dt = e.dataTransfer;
        const files = dt.files;

        if (files.length > 0) {
            handleFiles(files);
        } else if (dt.items && dt.items.length > 0) {
            for (let item of dt.items) {
                if (item.kind === 'file') {
                    handleFiles([item.getAsFile()]);
                    break;
                } else if (item.kind === 'string') {
                    item.getAsString((url) => {
                        fetch(url)
                            .then((response) => response.blob())
                            .then((blob) => {
                                const file = new File([blob], 'image_from_url', { type: blob.type });
                                handleFiles([file]);
                            })
                            .catch((error) => console.error('Error fetching image:', error));
                    });
                }
            }
        }
    };

    const handlePaste = (e) => {
        const items = e.clipboardData.items;
        for (let item of items) {
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                handleFiles([file]);
            }
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        const dropArea = dropAreaRef.current;
        dropArea.addEventListener('dragover', (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

        dropArea.addEventListener('drop', handleDrop);

        window.addEventListener('paste', handlePaste);

        return () => {
            dropArea.removeEventListener('dragover', (e) => {
                e.preventDefault();
                e.stopPropagation();
            });

            dropArea.removeEventListener('drop', handleDrop);

            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <div
            ref={dropAreaRef}
            onClick={handleClick}
            style={{
                border: '2px dashed #07c',
                borderRadius: '10px',
                boxSizing: "border-box",
                width: "100%",
                height: "40%",
                fontSize: "1.5em",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center',
                // backgroundColor: "red"
            }}
        >
            {image ? (
                <img
                    src={image}
                    alt="Uploaded"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                />
            ) : (
                <>Drag or Paste an ECG Image here<br/>Or click to upload one</>
            )}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => handleFiles(e.target.files)}
            />
        </div>
    );
};
