export const diagnosisNames = {
    "sinbrad": "Sinus bradycardia",
    "sinrhy": "Sinus rhythm",
    "sintach": "Sinus tachycardia",
    "pacerhy": "Paced rhythm",
    "afib": "Atrial fibrillation",
    "afibrapid": "Atrial fibrillation - rapid",
    "afibslow": "Atrial fibrillation - slow",
    "aflut": "Atrial flutter",
    "aflutrapid": "Atrial flutter - rapid",
    "aflutslow": "Atrial flutter - slow",
    "svt": "Supraventricular tachycardia",
    "junrhy": "Suspected junctional rhythm",
    "junbrad": "Suspected junctional bradycardia",
    "accjunrhy": "Suspected accelerated junctional rhythm",
    "wqrsrhy": "Wide QRS rhythm",
    "idiovenrhy": "Idioventricular rhythm",
    "wqrstach": "Wide QRS tachycardia",
    "pcom": "Premature complex",
    "avblock1": "1st degree AV block",
    "avblock2w": "2nd degree AV block, type Wenckebach",
    "avblockhd": "Higher degree AV block",
    "rbbb": "Complete right bundle branch block",
    "irbbb": "Incomplete right bundle branch block",
    "lbbb": "Complete left bundle branch block",
    "ilbbb": "Incomplete left bundle branch block",
    "ivcondelay": "Nonspecific intraventricular conduction delay",
    "lafb": "Left anterior fascicular block",
    "lpfb": "Left posterior fascicular block",
    "bifasblocka": "Bifascicular block (RBBB + LAFB)",
    "bifasblockp": "Bifascicular block (RBBB + LPFB)",
    "trifasblocka": "Trifascicular block (RBBB + LAFB + AVBLOCK1)",
    "trifasblockp": "Trifascicular block (RBBB + LPFB + AVBLOCK1)",
    "longqtsyn": "Suspected long QT syndrome",
    "shortqtsyn": "Suspected short QT syndrome",
    "atrenl": "Suspected atrial enlargement",
    "venhyp": "Suspected ventricular hypertrophy",
    "stemia": "Suspected ST-elevation ACS",
    "nstemi": "Suspected NonST-elevation ACS",
    "omi": "Occlusion Myocardial Infarction",
    "notomi": "Not Occlusion Myocardial Infarction",
}
